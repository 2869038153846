<template>
  <div id="users">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
      <vs-table :data="users" pagination :max-items="itemsPerPage" search>
        <template slot="thead">
          <vs-th></vs-th>
          <vs-th sort-key="user_id">{{ $t('userId') }}</vs-th>
          <vs-th sort-key="user_email">{{ $t('userEmail') }}</vs-th>
          <vs-th sort-key="first_name">{{ $t('firstName') }}</vs-th>
          <vs-th sort-key="last_name">{{ $t('lastName') }}</vs-th>
          <vs-th sort-key="projects_count">{{ $t('projectsCount') }}</vs-th>
          <vs-th sort-key="portals_count">{{ $t('portalsCount') }}</vs-th>
          <vs-th sort-key="portals">{{ $t('portals') }}</vs-th>
          <vs-th>{{ $t('actions') }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" :state="userStatusColor(data[indextr])">
            <!-- avatar -->
            <vs-td :data="data[indextr].user_id">
              <user-avatar 
                :key="`user_avatar_${data[indextr].user_id}`"
                :user="data[indextr]"
                size="small"
              />
            </vs-td>
            <!-- user_id -->
            <vs-td :data="data[indextr].user_email">
              <span>#{{data[indextr].user_id}}</span>
            </vs-td>
            <!-- user_email -->
            <vs-td :data="data[indextr].user_email">
              <span>{{data[indextr].user_email}}</span>
            </vs-td>
            <!-- first_name -->
            <vs-td :data="data[indextr].first_name">
              <span>{{decodeURIComponent(data[indextr].first_name) | capitalize}}</span>
            </vs-td>
            <!-- last_name -->
            <vs-td :data="data[indextr].last_name">
              <span>{{decodeURIComponent(data[indextr].last_name) | capitalize}}</span>
            </vs-td>
            <!-- projects_count -->
            <vs-td :data="data[indextr].projects_count">
              <span>{{data[indextr].projects_count}}</span>
            </vs-td>
            <!-- portals_count -->
            <vs-td :data="data[indextr].portals_count">
              <span>{{data[indextr].portals_count}}</span>
            </vs-td>
            <!-- portals -->
            <vs-td :data="data[indextr].portals">
              <template v-id="data[indextr].portals != null && data[indextr].portals != ''">
                <vs-chip v-for="(portal, index) in userPortals(data[indextr].portals)" :key="index">
                  <a class="text-white" :href="`/admin/portal/${portal}`" target="_blank" rel="nofollow">{{ portal }}</a>
                </vs-chip>
              </template>
            </vs-td>
            <!-- actions -->
            <vs-td :data="data[indextr].user_email">
              <a :href="`/admin/user/${data[indextr].user_email}`" target="_blank" rel="nofollow">{{ $t("edit") }}</a>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import vSelect from 'vue-select'
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from 'apexcharts'
import md5 from 'crypto-js/md5'

import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    moment,
    VueApexCharts, 
    vSelect, 
    Datepicker
  },
  data () {
    return {
      apexChatData,
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      appConfig: this.$appConfig,
      users: {},
      itemsPerPage: 50,
      loaded: false
    }
  },
  methods: {   
    stringColor (string) {
      return `#${this.intToRGB(this.hashCode(`${md5(string)}-${string}`))}`
    },
    hashCode (str) {
      let hash = 0
      if (typeof str !== undefined) {
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
      }
      return hash
    },
    intToRGB (i) {
      const c = (Math.pow(i, 2) & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()
      return '00000'.substring(0, 6 - c.length) + c
    },
    userPortals (string) {
      if (string == null || string == '') return null
      return string.includes(',') ? string.split(',') : [string]
    },
    refreshAll () {
      this.getUsersInfos()
    },
    getUsersInfos () {
      const params = ''
      const rimnat_api_call_url = `${this.api_server_baseurl}/admin/user/getUsersSynthesis?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          const users = response.data
          for (let i = 0; i < users.length; i++) {
            // convert string to int to allow sort in table
            users[i].user_size = parseInt(users[i].user_size)
            users[i].projects_count = parseInt(users[i].projects_count)
            if (users[i].conversion_end_date && users[i].conversion_end_date.length >= 10) users[i].conversion_end_date = parseInt(users[i].conversion_end_date.substring(0, 10).replaceAll('-', ''))
          }
          this.users = users
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getSizeColor (num) {
      if (num > 80)  return 'danger'
      if (num > 60)  return 'warning'
      if (num >= 40) return 'primary'
      if (num < 40)  return 'success'
      return 'primary'
    },
    userStatusColor (user) {
      let color = ''
      if (user.portals_count == 0) color = 'danger'
      if (user.projects == '') color = 'danger'
      return color
    }
  },
 
  computed: {

  },

  beforeMount () {
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.refreshAll()
  },

  watch: {
    scale () {
      this.refreshAll()
    },
    start () {
      this.refreshAll()
    },
    end () {
      this.refreshAll()
    }
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
